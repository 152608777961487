<template>
  <div class="relative">
    <NuxtLink
      class="hover:type-font-salt-ss02 peer hover:text-white"
      :to="props.to"
      @click="uiStore.showMenu = false"
    >
      <slot />
    </NuxtLink>
    <div
      class="absolute inset-y-0 right-full hidden items-center pr-[0.25em] peer-hover:flex"
    >
      <slot name="icon-left" />
    </div>
    <div
      class="absolute inset-y-0 left-full hidden items-center pl-[0.25em] peer-hover:flex"
    >
      <slot name="icon-right" />
    </div>
  </div>
</template>

<script setup lang="ts">
interface MenuNavLinkProps {
  to: string
}

const props = defineProps<MenuNavLinkProps>()
const uiStore = useUIStore()
</script>
