<template>
  <div>
    <Menu class="z-10" />
    <NavBar class="z-20" />
    <div class="overflow-hidden">
      <slot />
    </div>
    <div>
      <SvgMaskBlob />
      <SvgMaskScribble />
    </div>
  </div>
</template>

<script setup lang="ts">
useLayout()
</script>