<template>
  <Transition :css="false" @enter="onEnter" @leave="onLeave">
    <div
      v-if="uiStore.showMenu"
      ref="root"
      class="fixed inset-0 overflow-hidden bg-purple"
    >
      <div
        ref="inner"
        class="flex h-full w-full flex-col items-center justify-center space-y-12 pt-32 text-[40px]/[1.1em] font-medium md:pt-36 md:text-[75px]/[1.1em] xl:text-[90px]/[1.1em]"
      >
        <MenuNavLink to="/">
          <template #icon-left>
            <PayloadIcon
              class="h-[0.7em] rotate-[35deg] text-mint"
              icon="SvgIconArrow"
            />
          </template>
          The Movement
          <template #icon-right>
            <PayloadIcon class="h-[1em] text-yellow" icon="SvgIconConfusionA" />
          </template>
        </MenuNavLink>
        <MenuNavLink to="/why-wait">
          <template #icon-left>
            <PayloadIcon
              class="h-[0.6em] rotate-[35deg] text-pink"
              icon="SvgIconConfusionC"
            />
          </template>
          Why Wait?
          <template #icon-right>
            <div class="relative">
              <PayloadIcon
                class="h-[1em] -rotate-12 text-mint"
                icon="SvgIconAlarmClock"
              />
            </div>
          </template>
        </MenuNavLink>
        <MenuNavLink to="/resources">
          <template #icon-left>
            <PayloadIcon
              class="h-[0.7em] rotate-6 text-pink"
              icon="SvgIconBook"
            />
          </template>
          Resources
          <template #icon-right>
            <div class="relative">
              <PayloadIcon
                class="h-[0.8em] text-mint"
                icon="SvgIconFrustrationA"
              />
            </div>
          </template>
        </MenuNavLink>
        <MenuNavLink to="/about">
          <template #icon-left>
            <PayloadIcon class="h-[1em] text-yellow" icon="SvgIconExplosion" />
          </template>
          About
          <template #icon-right>
            <SvgIconWaitMate class="h-[1em] w-auto -rotate-6 text-mint" />
          </template>
        </MenuNavLink>
        <div class="flex md:hidden">
          <NuxtLink to="/pledge" class="btn btn-lg btn-black">
            Take the pledge
          </NuxtLink>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
const uiStore = useUIStore()

const { $anime } = useNuxtApp()

const onEnter = (root: Element, complete: () => void) => {
  const anim = $anime.timeline({ complete })
  const inner = root.children[0]

  anim
    .add({
      targets: [root, inner],
      translateY(_: HTMLElement, index: number) {
        return index ? ['100%', 0] : ['-100%', 0]
      },
      translateZ: 0,
      duration: 1000,
      easing: slideEasing,
    })
    .add(
      {
        targets: inner.children,
        translateY: [-20, 0],
        translateZ: 0,
        opacity: [0, 1],
        duration: 500,
        delay: $anime.stagger(100),
        easing: slideEasing,
      },
      250,
    )
}

const onLeave = (root: Element, complete: () => void) => {
  const anim = $anime.timeline({ complete })
  const inner = root.children[0]

  anim
    .add({
      targets: [root, inner],
      translateY(_: HTMLElement, index: number) {
        return index ? '100%' : '-100%'
      },
      translateZ: 0,
      duration: 1000,
      easing: slideEasing,
    })
    .add(
      {
        targets: inner.children,
        opacity: [1, 0],
        duration: 250,
        easing: 'linear',
      },
      0,
    )
}
</script>
