<template>
  <div class="fixed inset-x-0 top-0 h-0">
    <DefaultGrid
      class="pt-10 transition-transform duration-300"
      :class="show || uiStore.showMenu ? '' : '-translate-y-full'"
    >
      <div class="col-span-full flex items-center">
        <div class="flex flex-1 justify-start">
          <NavBarMenuButton class="hidden md:block" />
        </div>
        <div class="flex flex-1 justify-center">
          <NuxtLink to="/" @click="uiStore.showMenu = false">
            <SvgLogo
              class="h-[85px] md:h-[105px]"
              :class="uiStore.showMenu ? 'text-purple' : 'text-white'"
            />
          </NuxtLink>
        </div>
        <div class="flex flex-1 justify-end">
          <div class="hidden md:block">
            <NuxtLink
              to="/pledge"
              class="btn btn-sm btn-black"
              @click="uiStore.showMenu = false"
            >
              Take the pledge
            </NuxtLink>
          </div>
          <NavBarMenuButton class="md:hidden" />
        </div>
      </div>
    </DefaultGrid>
  </div>
</template>

<script setup lang="ts">
const uiStore = useUIStore()

const show = ref(true)
const lastScrollY = ref(0)

const { y: scrollY } = useWindowScroll()

onMounted(() => {
  lastScrollY.value = scrollY.value
})

watch(scrollY, (newValue) => {
  const clampedScrollY = Math.max(0, newValue)
  const deltaScrollY = clampedScrollY - lastScrollY.value

  if (show.value) {
    show.value = !(deltaScrollY > 20)
  } else {
    show.value = deltaScrollY < -20 || newValue <= 0
  }

  lastScrollY.value = clampedScrollY
})
</script>
