<template>
  <BaseButton
    class="h-10 bg-white px-5 shadow-lg md:h-12 md:px-6"
    @click="uiStore.showMenu = !uiStore.showMenu"
  >
    <div class="flex flex-col space-y-1">
      <div
        v-for="n in 2"
        :key="n"
        class="h-[3px] w-6 rounded-full bg-black transition-transform duration-200"
        :class="
          uiStore.showMenu
            ? n === 1
              ? 'translate-y-[3.5px]'
              : '-translate-y-[3.5px]'
            : ''
        "
      />
    </div>
  </BaseButton>
</template>

<script setup lang="ts">
const uiStore = useUIStore()
</script>
